import React from 'react';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import renewalItemsHook from 'hooks/renewal-items/renewal-items.hook';

import s from './RenewalItems.scss';

export default (): JSX.Element => {
    const items = renewalItemsHook();

    return (
        <div className={s.renewalItems}>
            {items.map(({ node }) => (
                <div key={`renewal-item-${node.directusId}`} className={s.renewalItems__item}>
                    <div className={s.renewalItems__header}>
                        <h4 className={s.renewalItems__title}>
                            <Markdown source={node.title} />
                        </h4>
                        <div className={s.renewalItems__price}>
                            <PriceWithoutVat price={node.price.value} />
                        </div>
                    </div>
                    <div className={s.renewalItems__body}>
                        <Markdown className={s.renewalItems__content} container source={node.body} />
                        <div className={s.renewalItems__buttons}>
                            <Button
                                role="primary"
                                className={s.renewalItems__buyButton}
                                to={node.button_link}
                                ariaLabel={`Order our ${node.title} service now.`}
                            >
                                {node.button_text}
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
